import { IUploadScopeDTO, IUploadPPA } from "@/models/FormData";
import {
  IScopeTwoParameters,
  IScopeTwoRequestDetailParamsDTO,
  IScopeTwoRequestTotal,
  IScopeTwoPPADetailParameters,
} from "@/models/ScopeTwoEmissions";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const ENERGY_LOADS = "ScopeTwoEnergyLoads";
const GENERATED_ENERGIES = "ScopeTwoGeneratedEnergies";
const RENEWABLES_ENERGIES = "ScopeTwoRenewableEnergies";
const PPA = "PowerPurchaseAgreements";

export class S2RPAPI extends HttpClient {
  constructor() {
    super(process.env.VUE_APP_DOMAIN_API_SERVER);
  }
  public uploadScopeTwoGeneratedEnergy = async (
    data: IUploadScopeDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${GENERATED_ENERGIES}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoGeneratedEnergyRequest = async (
    params: IScopeTwoParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GENERATED_ENERGIES}/${params.Year}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoGeneratedEnergyRequestDetail = async (
    params: IScopeTwoRequestDetailParamsDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GENERATED_ENERGIES}/${params.Year}/requests/${params.requestId}`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoGeneratedEnergyTotal = async (
    params: IScopeTwoRequestTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GENERATED_ENERGIES}/${params.Year}/total/${params.FacilityId}`)
      .catch(catchAxiosError);

    return response;
  };

  public uploadScopeTwoRenewableEnergy = async (
    data: IUploadScopeDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${RENEWABLES_ENERGIES}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoRenewableEnergyRequest = async (
    params: IScopeTwoParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLES_ENERGIES}/${params.Year}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };
  public getScopeTwoRenewableEnergyRequestDetail = async (
    params: IScopeTwoRequestDetailParamsDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLES_ENERGIES}/${params.Year}/requests/${params.requestId}`)
      .catch(catchAxiosError);

    return response;
  };
  public getScopeTwoRenewablesEnergyTotal = async (
    params: IScopeTwoRequestTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${RENEWABLES_ENERGIES}/${params.Year}/total/${params.FacilityId}`)
      .catch(catchAxiosError);

    return response;
  };

  public uploadScopeTwoEnergyLoads = async (
    data: IUploadScopeDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${ENERGY_LOADS}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEnergyLoadsRequest = async (
    params: IScopeTwoParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${ENERGY_LOADS}/${params.Year}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEnergyLoadsRequestDetail = async (
    params: IScopeTwoRequestDetailParamsDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${ENERGY_LOADS}/${params.Year}/requests/${params.requestId}`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeTwoEnergyLoadsTotal = async (
    params: IScopeTwoRequestTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${ENERGY_LOADS}/${params.Year}/total/${params.FacilityId}`)
      .catch(catchAxiosError);

    return response;
  };

  public uploadPPA = async (data: IUploadPPA): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${PPA}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getPPARequest = async (
    params: IScopeTwoParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${PPA}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getPPARequestDetail = async (
    params: IScopeTwoPPADetailParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${PPA}/${params.ppaId}`)
      .catch(catchAxiosError);

    return response;
  };
}
