import { IUploadScopeDTO } from "@/models/FormData";
import { IPagination } from "@/models/Pagination";
import {
  IScopeTwoDataChart,
  IScopeTwoEnergyLoadDTO,
  IScopeTwoGridEmissionFactor,
  IScopeTwoParameters,
  IScopeTwoRequestDetailParamsDTO,
  IScopeTwoRequestTotal,
  IScopeTwoResponseMonthValue,
  IScopeTwoResponseTotal,
} from "@/models/ScopeTwoEmissions";
import { ErrorResponse } from "@/services/axios/error";
import { S2RPAPI } from "@/services/s2rp";
import store from "@/store";
import {
  convertDataToMonthValue,
  convertDataToMonthValueWithFormat,
} from "@/utils/helpers/buildDataFormat";
import { covertDataChartMonthValue } from "@/utils/helpers/convertDataChart";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";

const name = "ScopeTwoEnergies";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class ScopeTwoEnergiesModule extends VuexModule {
  private pagination = {} as IPagination;

  private errorUploadGeneratedEnergy = {} as ErrorResponse;
  private errorUploadEnergyLoads = {} as ErrorResponse;
  private erroruploadRenewableEnergy = {} as ErrorResponse;
  private errorEnergyLoadsRequest = {} as ErrorResponse;
  private errorEnergyLoadsRequestDetail = {} as ErrorResponse;
  private errorGeneratedEnergyRequest = {} as ErrorResponse;
  private errorGeneratedEnergyRequestDetail = {} as ErrorResponse;
  private errorRenewablesEnergyRequest = {} as ErrorResponse;
  private errorRenewablesEnergyRequestDetail = {} as ErrorResponse;
  private errorEnergyLoadsTotal = {} as ErrorResponse;
  private errorGeneratedEnergyTotal = {} as ErrorResponse;
  private errorRenewablesEnergyTotal = {} as ErrorResponse;

  private energyLoadsData = [] as IScopeTwoEnergyLoadDTO[];
  private energyLoadsDetail = {} as any;
  private energyLoadsTotal = {} as IScopeTwoResponseMonthValue;
  private energyLoadsChart = [] as IScopeTwoDataChart[][];
  private energyLoadsGridEmissionFactor = {} as IScopeTwoGridEmissionFactor;

  private generatedEnergyData = [] as IScopeTwoEnergyLoadDTO[];
  private generatedEnergyDetail = {} as any;
  private generatedEnergyTotal = {} as IScopeTwoResponseMonthValue;
  private generatedEnergyChart = [] as IScopeTwoDataChart[][];

  private renewablesEnergyData = [] as IScopeTwoEnergyLoadDTO[];
  private renewablesEnergyDetail = {} as any;
  private renewablesEnergyTotal = {} as IScopeTwoResponseMonthValue;
  private renewablesEnergyChart = [] as IScopeTwoDataChart[][];

  private isLoadingEnergyLoadsRequestDetail = false;
  private isLoadingEnergyLoadsRequest = false;
  private isLoadingEnergyLoadsTotal = false;

  private isLoadingGeneratedEnergyRequestDetail = false;
  private isLoadingGeneratedEnergyRequest = false;
  private isLoadingGeneratedEnergyTotal = false;

  private isLoadingRenewablesEnergyRequestDetail = false;
  private isLoadingRenewablesEnergyRequest = false;
  private isLoadingRenewablesEnergyTotal = false;

  private isReadonlyEnergyLoadsForm = false;
  private isReadonlyGeneratedEnergyForm = false;
  private isReadonlyRenewablesEnergyForm = false;

  private currentOptionYearEnergyLoads = "";
  private currentOptionYearGeneratedEnergy = "";
  private currentOptionYearRenewablesEnergy = "";

  get isLoadingEnergyLoadsRequestGetter() {
    return this.isLoadingEnergyLoadsRequest;
  }

  get isLoadingGeneratedEnergyRequestGetter() {
    return this.isLoadingGeneratedEnergyRequest;
  }

  get isLoadingRenewablesEnergyRequestGetter() {
    return this.isLoadingRenewablesEnergyRequest;
  }

  get energyLoadGetter() {
    return this.energyLoadsData;
  }

  get generatedEnergyGetter() {
    return this.generatedEnergyData;
  }

  get renewablesEnergyGetter() {
    return this.renewablesEnergyData;
  }

  get energyLoadDetailGetter() {
    return this.energyLoadsDetail;
  }

  get generatedEnergyDetailGetter() {
    return this.generatedEnergyDetail;
  }

  get renewablesEnergyDetailGetter() {
    return this.renewablesEnergyDetail;
  }

  get currentOptionYearEnergyLoadGetter() {
    return this.currentOptionYearEnergyLoads;
  }

  get currentOptionYearGeneratedGetter() {
    return this.currentOptionYearGeneratedEnergy;
  }

  get currentOptionYearRenewablesGetter() {
    return this.currentOptionYearRenewablesEnergy;
  }

  get isReadonlyEnergyLoadsFormGetter() {
    return this.isReadonlyEnergyLoadsForm;
  }

  get isReadonlyGeneratedEnergyFormGetter() {
    return this.isReadonlyGeneratedEnergyForm;
  }

  get isReadonlyRenewablesEnergyFormGetter() {
    return this.isReadonlyRenewablesEnergyForm;
  }

  get isLoadingEnergyLoadsRequestDetailGetter() {
    return this.isLoadingEnergyLoadsRequestDetail;
  }

  get isLoadingGeneratedEnergyRequestDetailGetter() {
    return this.isLoadingGeneratedEnergyRequestDetail;
  }

  get isLoadingRenewablesEnergyRequestDetailGetter() {
    return this.isLoadingRenewablesEnergyRequestDetail;
  }

  get energyLoadsTotalGetter() {
    return this.energyLoadsTotal;
  }

  get isLoadingEnergyLoadsTotalGetter() {
    return this.isLoadingEnergyLoadsTotal;
  }
  get energyLoadsChartGetter() {
    return this.energyLoadsChart;
  }
  get energyLoadGridEmissionFactorGetter() {
    return this.energyLoadsGridEmissionFactor;
  }
  get generatedEnergyTotalGetter() {
    return this.generatedEnergyTotal;
  }

  get isLoadingGeneratedEnergyTotalGetter() {
    return this.isLoadingGeneratedEnergyTotal;
  }
  get generatedEnergyChartGetter() {
    return this.generatedEnergyChart;
  }

  get renewablesEnergyTotalGetter() {
    return this.renewablesEnergyTotal;
  }

  get isLoadingRenewablesEnergyTotalGetter() {
    return this.isLoadingRenewablesEnergyTotal;
  }
  get renewablesEnergyChartGetter() {
    return this.renewablesEnergyChart;
  }
  @Mutation
  storageCurrentOptionYearEnergyLoads(value: string) {
    this.currentOptionYearEnergyLoads = value;
  }
  @Mutation
  storageCurrentOptionYearGeneratedEnergy(value: string) {
    this.currentOptionYearGeneratedEnergy = value;
  }
  @Mutation
  storageCurrentOptionYearRenewablesEnergy(value: string) {
    this.currentOptionYearRenewablesEnergy = value;
  }

  @Mutation
  toggleEnergyLoadsButtonType(isReadonlyEnergyLoadsForm = false) {
    this.isReadonlyEnergyLoadsForm = isReadonlyEnergyLoadsForm;
  }

  @Mutation
  toggleGeneratedEnergyButtonType(isReadonlyGeneratedEnergyForm = false) {
    this.isReadonlyGeneratedEnergyForm = isReadonlyGeneratedEnergyForm;
  }

  @Mutation
  toggleRenewablesEnergyButtonType(isReadonlyRenewablesEnergyForm = false) {
    this.isReadonlyRenewablesEnergyForm = isReadonlyRenewablesEnergyForm;
  }

  @Mutation
  emptyEnergyLoadsRequest() {
    const { Collection, ...rest } = {} as any;
    this.energyLoadsData = Collection;
    this.pagination = rest;
  }
  @Mutation
  emptyEnergyLoadsRequestDetail() {
    this.energyLoadsDetail = { monthValue: [] };
  }

  @Mutation
  emptyGeneratedRequest() {
    const { Collection, ...rest } = {} as any;
    this.generatedEnergyData = Collection;
    this.pagination = rest;
  }
  @Mutation
  emptyGeneratedRequestDetail() {
    this.generatedEnergyDetail = { monthValue: [] };
  }

  @Mutation
  emptyRenewablesRequest() {
    const { Collection, ...rest } = {} as any;
    this.renewablesEnergyData = Collection;
    this.pagination = rest;
  }
  @Mutation
  emptyRenewablesRequestDetail() {
    this.renewablesEnergyDetail = { monthValue: [] };
  }

  @Mutation
  uploadGeneratedEnergySuccess() {
    ElMessage({
      message: "Upload Generated Energies success.",
      grouping: true,
      type: "success",
    });
    this.errorUploadGeneratedEnergy = {} as ErrorResponse;
  }
  @Mutation
  uploadGeneratedEnergyFailure(error: ErrorResponse) {
    this.errorUploadGeneratedEnergy = error;
  }
  @Action
  async uploadGeneratedEnergyAction(payload: IUploadScopeDTO) {
    const S2RPAPIService = new S2RPAPI();
    const { error } = await S2RPAPIService.uploadScopeTwoGeneratedEnergy(
      payload
    );
    if (error) {
      this.uploadGeneratedEnergyFailure(error);
      return;
    }
    this.uploadGeneratedEnergySuccess();
  }

  @Mutation
  uploadEnergyLoadsSuccess() {
    ElMessage({
      message: "Upload Energy Loads success.",
      grouping: true,
      type: "success",
    });
    this.errorUploadEnergyLoads = {} as ErrorResponse;
  }
  @Mutation
  uploadEnergyLoadsFailure(error: ErrorResponse) {
    this.errorUploadEnergyLoads = error;
  }
  @Action
  async uploadEnergyLoadsAction(payload: IUploadScopeDTO) {
    const S2RPAPIService = new S2RPAPI();
    const { error } = await S2RPAPIService.uploadScopeTwoEnergyLoads(payload);
    if (error) {
      this.uploadEnergyLoadsFailure(error);
      return;
    }
    this.uploadEnergyLoadsSuccess();
  }

  @Mutation
  uploadScopeTwoRenewableEnergySuccess() {
    ElMessage({
      message: "Upload Renewable Energies success.",
      grouping: true,
      type: "success",
    });
    this.erroruploadRenewableEnergy = {} as ErrorResponse;
  }
  @Mutation
  uploadScopeTwoRenewableEnergyFailure(error: ErrorResponse) {
    this.erroruploadRenewableEnergy = error;
  }
  @Action
  async uploadRenewableEnergyAction(payload: IUploadScopeDTO) {
    const S2RPAPIService = new S2RPAPI();
    const { error } = await S2RPAPIService.uploadScopeTwoRenewableEnergy(
      payload
    );
    if (error) {
      this.uploadScopeTwoRenewableEnergyFailure(error);
      return;
    }
    this.uploadScopeTwoRenewableEnergySuccess();
  }

  @Mutation
  loadingEnergyLoadRequest(loading: boolean) {
    this.isLoadingEnergyLoadsRequest = loading;
  }
  @Mutation
  getEnergyLoadRequestFailure(error: ErrorResponse) {
    this.errorEnergyLoadsRequest = error;
    this.isLoadingEnergyLoadsRequest = false;
  }
  @Mutation
  getEnergyLoadRequestSuccess(data: any) {
    if (data?.Collection) {
      const { Collection, ...rest } = data;
      this.errorEnergyLoadsRequest = {} as ErrorResponse;
      this.energyLoadsData = Collection;
      this.pagination = rest;
    } else {
      this.energyLoadsData = [];
    }
    this.isLoadingEnergyLoadsRequest = false;
  }
  @Action
  async getEnergyLoadRequestAction(payload: IScopeTwoParameters) {
    this.loadingEnergyLoadRequest(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } = await S2RPAPIService.getScopeTwoEnergyLoadsRequest(
      payload
    );
    if (error) {
      this.getEnergyLoadRequestFailure(error);
      return;
    }
    this.getEnergyLoadRequestSuccess(data);
  }

  @Mutation
  loadingEnergyLoadsRequestDetail(isLoading: boolean) {
    this.isLoadingEnergyLoadsRequestDetail = isLoading;
  }
  @Mutation
  getEnergyLoadRequestDetailFailure(error: ErrorResponse) {
    this.isLoadingEnergyLoadsRequestDetail = false;
    this.errorEnergyLoadsRequestDetail = error;
  }
  @Mutation
  getEnergyLoadRequestDetailSuccess(data: any) {
    this.isLoadingEnergyLoadsRequestDetail = false;
    this.errorEnergyLoadsRequestDetail = {} as ErrorResponse;
    this.energyLoadsDetail = convertDataToMonthValueWithFormat(data) as any;
  }
  @Action
  async getEnergyLoadRequestDetailAction(
    payload: IScopeTwoRequestDetailParamsDTO
  ) {
    this.loadingEnergyLoadsRequestDetail(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } =
      await S2RPAPIService.getScopeTwoEnergyLoadsRequestDetail(payload);
    if (error) {
      this.getEnergyLoadRequestDetailFailure(error);
      return;
    }
    this.getEnergyLoadRequestDetailSuccess(data);
  }

  @Mutation
  loadingGeneratedEnergyRequest(loading: boolean) {
    this.isLoadingGeneratedEnergyRequest = loading;
  }
  @Mutation
  getGeneratedEnergyRequestFailure(error: ErrorResponse) {
    this.errorGeneratedEnergyRequest = error;
    this.isLoadingGeneratedEnergyRequest = false;
    this.generatedEnergyData = [];
  }
  @Mutation
  getGeneratedEnergyRequestSuccess(data: any) {
    const { Collection, ...rest } = data;
    this.errorGeneratedEnergyRequest = {} as ErrorResponse;
    this.generatedEnergyData = Collection;
    this.pagination = rest;
    this.isLoadingGeneratedEnergyRequest = false;
  }
  @Action
  async getGeneratedEnergyRequestAction(payload: IScopeTwoParameters) {
    this.loadingGeneratedEnergyRequest(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } =
      await S2RPAPIService.getScopeTwoGeneratedEnergyRequest(payload);
    if (error) {
      this.getGeneratedEnergyRequestFailure(error);
      return;
    }
    this.getGeneratedEnergyRequestSuccess(data);
  }

  @Mutation
  loadingGeneratedEnergyRequestDetail(isLoading: boolean) {
    this.isLoadingGeneratedEnergyRequestDetail = isLoading;
  }
  @Mutation
  getGeneratedEnergyRequestDetailFailure(error: ErrorResponse) {
    this.isLoadingGeneratedEnergyRequestDetail = false;
    this.errorGeneratedEnergyRequestDetail = error;
  }
  @Mutation
  getGeneratedEnergyRequestDetailSuccess(data: any) {
    this.isLoadingGeneratedEnergyRequestDetail = false;
    this.errorGeneratedEnergyRequestDetail = {} as ErrorResponse;
    this.generatedEnergyDetail = convertDataToMonthValue(data) as any;
  }
  @Action
  async getGeneratedEnergyRequestDetailAction(
    payload: IScopeTwoRequestDetailParamsDTO
  ) {
    this.loadingGeneratedEnergyRequestDetail(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } =
      await S2RPAPIService.getScopeTwoGeneratedEnergyRequestDetail(payload);
    if (error) {
      this.getGeneratedEnergyRequestDetailFailure(error);
      return;
    }
    this.getGeneratedEnergyRequestDetailSuccess(data);
  }

  @Mutation
  loadingRenewablesEnergyRequest(loading: boolean) {
    this.isLoadingRenewablesEnergyRequest = loading;
  }
  @Mutation
  getRenewablesEnergyFailure(error: ErrorResponse) {
    this.errorRenewablesEnergyRequest = error;
    this.isLoadingRenewablesEnergyRequest = false;
    this.renewablesEnergyData = [];
  }
  @Mutation
  getRenewablesEnergySuccess(data: any) {
    const { Collection, ...rest } = data;
    this.errorRenewablesEnergyRequest = {} as ErrorResponse;
    this.renewablesEnergyData = Collection;
    this.pagination = rest;
    this.isLoadingRenewablesEnergyRequest = false;
  }
  @Action
  async getRenewablesEnergyRequestAction(payload: IScopeTwoParameters) {
    this.loadingRenewablesEnergyRequest(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } =
      await S2RPAPIService.getScopeTwoRenewableEnergyRequest(payload);
    if (error) {
      this.getRenewablesEnergyFailure(error);
      return;
    }
    this.getRenewablesEnergySuccess(data);
  }
  @Mutation
  loadingRenewablesEnergyRequestDetail(isLoading: boolean) {
    this.isLoadingRenewablesEnergyRequestDetail = isLoading;
  }
  @Mutation
  getRenewablesEnergyRequestDetailFailure(error: ErrorResponse) {
    this.isLoadingRenewablesEnergyRequestDetail = false;
    this.errorRenewablesEnergyRequestDetail = error;
  }
  @Mutation
  getRenewablesEnergyRequestDetailSuccess(data: any) {
    this.isLoadingRenewablesEnergyRequestDetail = false;
    this.errorRenewablesEnergyRequestDetail = {} as ErrorResponse;
    this.renewablesEnergyDetail = convertDataToMonthValue(data) as any;
  }
  @Action
  async getRenewablesEnergyRequestDetailAction(
    payload: IScopeTwoRequestDetailParamsDTO
  ) {
    this.loadingRenewablesEnergyRequestDetail(true);
    const S2RPAPIService = new S2RPAPI();
    const { data, error } =
      await S2RPAPIService.getScopeTwoRenewableEnergyRequestDetail(payload);
    if (error) {
      this.getRenewablesEnergyRequestDetailFailure(error);
      return;
    }
    this.getRenewablesEnergyRequestDetailSuccess(data);
  }
  @Mutation
  emptyEnergyLoadTotal() {
    this.energyLoadsTotal = {} as IScopeTwoResponseMonthValue;
    this.energyLoadsChart = [];
    this.energyLoadsGridEmissionFactor = {} as IScopeTwoGridEmissionFactor;
  }
  @Mutation
  loadingEnergyLoadTotal(isLoading: boolean) {
    this.isLoadingEnergyLoadsTotal = isLoading;
  }
  @Mutation
  getEnergyLoadTotalFailure(error: ErrorResponse) {
    this.isLoadingEnergyLoadsTotal = false;
    this.errorEnergyLoadsTotal = error;
    this.energyLoadsTotal = {} as IScopeTwoResponseMonthValue;
    this.energyLoadsChart = [] as IScopeTwoDataChart[][];
    this.energyLoadsGridEmissionFactor = {} as IScopeTwoGridEmissionFactor;
  }
  @Mutation
  getEnergyLoadTotalSuccess(data: IScopeTwoResponseTotal) {
    this.isLoadingEnergyLoadsTotal = false;
    this.errorEnergyLoadsTotal = {} as ErrorResponse;
    this.energyLoadsTotal = convertDataToMonthValueWithFormat(
      data.MonthValue
    ) as any;
    this.energyLoadsChart = covertDataChartMonthValue(data.MonthValue);
    this.energyLoadsGridEmissionFactor = {
      GridEmissionFactor: data.GridEmissionFactor,
    } as IScopeTwoGridEmissionFactor;
  }
  @Action
  async getEnergyLoadTotalAction(payload: IScopeTwoRequestTotal) {
    const S2RPAPIService = new S2RPAPI();
    this.loadingEnergyLoadTotal(true);
    const { data, error } = await S2RPAPIService.getScopeTwoEnergyLoadsTotal(
      payload
    );
    if (error) {
      this.getEnergyLoadTotalFailure(error);
      return;
    }
    this.getEnergyLoadTotalSuccess(data);
  }
  @Mutation
  emptyGeneratedEnergyTotal() {
    this.generatedEnergyTotal = {} as IScopeTwoResponseMonthValue;
    this.generatedEnergyChart = [];
    this.energyLoadsGridEmissionFactor = {} as IScopeTwoGridEmissionFactor;
  }
  @Mutation
  loadingGeneratedTotal(isLoading: boolean) {
    this.isLoadingGeneratedEnergyTotal = isLoading;
  }
  @Mutation
  getGeneratedTotalFailure(error: ErrorResponse) {
    this.isLoadingGeneratedEnergyTotal = false;
    this.errorGeneratedEnergyTotal = error;
    this.generatedEnergyTotal = {} as IScopeTwoResponseMonthValue;
    this.generatedEnergyChart = [];
  }
  @Mutation
  getGeneratedTotalSuccess(data: IScopeTwoResponseTotal) {
    this.isLoadingGeneratedEnergyTotal = false;
    this.errorGeneratedEnergyTotal = {} as ErrorResponse;
    this.generatedEnergyTotal = convertDataToMonthValueWithFormat(data) as any;
    this.generatedEnergyChart = covertDataChartMonthValue(data);
  }
  @Action
  async getGeneratedEnergyTotalAction(payload: IScopeTwoRequestTotal) {
    const S2RPAPIService = new S2RPAPI();
    this.loadingGeneratedTotal(true);
    const { data, error } =
      await S2RPAPIService.getScopeTwoGeneratedEnergyTotal(payload);
    if (error) {
      this.getGeneratedTotalFailure(error);
      return;
    }
    this.getGeneratedTotalSuccess(data);
  }
  @Mutation
  emptyRenewablesEnergyTotal() {
    this.renewablesEnergyTotal = {} as IScopeTwoResponseMonthValue;
    this.renewablesEnergyChart = [];
  }
  @Mutation
  loadingRenewablesEnergyTotal(isLoading: boolean) {
    this.isLoadingRenewablesEnergyTotal = isLoading;
  }
  @Mutation
  getRenewablesEnergyTotalFailure(error: ErrorResponse) {
    this.isLoadingRenewablesEnergyTotal = false;
    this.errorGeneratedEnergyTotal = error;
  }
  @Mutation
  getRenewablesEnergyTotalSuccess(data: IScopeTwoResponseTotal) {
    this.isLoadingRenewablesEnergyTotal = false;
    this.errorRenewablesEnergyTotal = {} as ErrorResponse;
    this.renewablesEnergyTotal = convertDataToMonthValueWithFormat(data) as any;
    this.renewablesEnergyChart = covertDataChartMonthValue(data);
  }
  @Action
  async getRenewablesEnergyTotalAction(payload: IScopeTwoRequestTotal) {
    const S2RPAPIService = new S2RPAPI();
    this.loadingRenewablesEnergyTotal(true);
    const { data, error } =
      await S2RPAPIService.getScopeTwoRenewablesEnergyTotal(payload);
    if (error) {
      this.getRenewablesEnergyTotalFailure(error);
      return;
    }
    this.getRenewablesEnergyTotalSuccess(data);
  }
}

export default getModule(ScopeTwoEnergiesModule);
