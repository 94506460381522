import { IScopeTwoDataChart } from "@/models/ScopeTwoEmissions";
import { formatMonthOfWeek } from "./convertDateTime";
import isEmpty from "lodash/isEmpty";
import sum from "lodash/sum";
import {
  IGHGTargets,
  IGHGTargetsLongTermAndShortTermDetail,
} from "@/models/SettingBaseLine";
export const covertDataChart = (
  data: any[] = [],
  showKey1: string,
  showKey2: string
) => {
  const dataConvert: any =
    data &&
    data.map((item: any) => {
      const arr = [] as any[];
      arr.push(item[showKey1], item[showKey2]);
      return arr;
    });
  return dataConvert;
};

export const covertDataChartScope1And3 = (data: any = {}) => {
  const parseDataArr = Object.entries(data);
  return parseDataArr.map((item) => {
    const arr = [] as any[];
    arr.push(parseInt(item[0]), item[1]);
    return arr;
  });
};

export const covertDataChartScope2 = (
  data: any[] = [],
  showKey1: string,
  showKey2: string
) => {
  const dataConvert: any = data.map((item: any) => {
    const arr = [] as any[];
    arr.push(formatMonthOfWeek(item[showKey1] - 1), item[showKey2]);
    return arr;
  });
  return dataConvert;
};

export const covertDataChartMonthValue = (data: any = {}) => {
  const arrayEmpty = [] as any[];
  if (data) {
    const parseDataArr = Object.entries(data);
    return parseDataArr.map((item, index) => {
      const arr = [] as any[];
      arr.push(formatMonthOfWeek(index), item[1]);
      return arr;
    });
  }
  return arrayEmpty;
};

export const covertDataChartYearValue = (data: any = {}) => {
  const arrayEmpty = [] as any[];
  if (data) {
    const parseDataArr = Object.entries(data);
    return parseDataArr.map((item, index) => {
      const arr = [] as any[];
      arr.push(item[0], item[1]);
      return arr;
    });
  }
  return arrayEmpty;
};

export const covertDataScope2Target = (data: any = {}) => {
  const dataConvert = [];
  if (!isEmpty(data)) {
    for (let i = 0; i <= 11; i++) {
      const arr = [];
      arr.push(formatMonthOfWeek(i), data.Target);
      dataConvert.push(arr);
    }
  }
  return dataConvert;
};

export const covertDataChartLine = (
  dataRenewablePercentages: IScopeTwoDataChart
) => {
  const emptyData = [] as any[];
  let dataSum = "0";
  const percentEmpty = 0;
  if (!isEmpty(dataRenewablePercentages)) {
    const parseDataArr = Object.entries(dataRenewablePercentages);
    const dataConvert = parseDataArr.map((item, index) => {
      const arr = [] as any[];
      arr.push(formatMonthOfWeek(index), item[1] * 100);
      return arr;
    });
    dataSum = (dataRenewablePercentages.December * 100).toFixed(0);
    const values: any = Object.values(dataRenewablePercentages);
    const max = Math.max(...values);
    return {
      dataConvert,
      dataSum,
      maxPercent: Math.round(max) < max ? Math.round(max) + 1 : Math.round(max),
    };
  }
  return {
    dataConvert: emptyData,
    dataSum,
    maxPercent: percentEmpty,
  };
};
export const covertDataChartTime = (data: any = {}) => {
  const arr = Object.entries(data);
  return arr;
};

export const convertDataPerformanceReview = (
  data: any = {},
  series: any = {}
) => {
  if (isEmpty(data) || sum(Object.values(data)) === 0)
    return [
      {
        x: "No Data",
        value: 100,
        fill: "#6B7280",
      },
    ];
  return Object.keys(data).map((key) => ({
    x: series[key].label,
    value: parseFloat(data[key]).toPrecision(3),
    fill: series[key].color,
  }));
};

export const covertDataTarget = (target: number, year: any) => {
  const minYear = Number(year) - 9;
  const dataConvert = [];
  for (let i = minYear; i <= Number(year); i++) {
    const arr = [];
    arr.push(`${i}`, target);
    dataConvert.push(arr);
  }
  return dataConvert;
};

export const convertDataWorldMap = (
  data: any,
  showKey1: string,
  showKey2: string
) => {
  const dataConvert: any =
    data &&
    Object.entries(data).map((item: any) => ({
      [`${showKey1}`]: item[0],
      [`${showKey2}`]: item[1],
    }));
  return dataConvert;
};

export const totalHistory = (
  scopeOneHistory: any,
  scopeTwoHistory: any,
  scopeThreeHistory: any,
  baselineData: IGHGTargets
) => {
  const dataTotal = [];
  const dataTempChart = [];
  if (baselineData) {
    for (
      let year = baselineData.BaseYear;
      year <= baselineData.LongTermTargetYear;
      year++
    ) {
      const arr = [];
      const scopeOneHistoryDetail = scopeOneHistory[year]
        ? scopeOneHistory[year]
        : 0;
      const scopeTwoHistoryDetail = scopeTwoHistory[year]
        ? scopeTwoHistory[year]
        : 0;
      const scopeThreeHistoryDetail = scopeThreeHistory[year]
        ? scopeThreeHistory[year]
        : 0;
      const total =
        scopeTwoHistoryDetail + scopeOneHistoryDetail + scopeThreeHistoryDetail;
      dataTempChart.push(total);
      arr.push(year, total);
      dataTotal.push(arr);
    }
  }
  return { dataTotal, valueLeftColumnChart: Math.max(...dataTempChart) };
};
export const shortTermAndLongTerm = (
  data: IGHGTargetsLongTermAndShortTermDetail
) => {
  if (data?.Details?.length > 0) {
    const { Details } = data;
    return Details.map((item) => {
      const arr = [];
      arr.push(item.Year, item.TargetEmissionAfterReduction);
      return arr;
    });
  }
};

export const parseDataHistory = (
  dataHistory: any,
  baselineData: IGHGTargets
) => {
  const dataHistoryFinal = [];
  if (baselineData) {
    for (
      let year = baselineData.BaseYear;
      year <= baselineData.LongTermTargetYear;
      year++
    ) {
      const arr = [];
      const dataTemp = dataHistory[year] ? dataHistory[year] : 0;
      arr.push(year, dataTemp);
      dataHistoryFinal.push(arr);
    }
  }
  return dataHistoryFinal;
};
