import { IResponse } from "@/services/axios/entities";
import { ElMessage } from "element-plus/lib/components/message";
import isEmpty from "lodash/isEmpty";
import { onMounted } from "vue";
import { onBeforeRouteUpdate, useRouter } from "vue-router";

export const useRedirectOnBeforeRouteUpdate = (
  cb: (param: any) => Promise<IResponse>,
  key: string,
  path: string
) => {
  const router = useRouter();

  onBeforeRouteUpdate(async (to, from) => {
    if (to.params[key] !== from.params[key]) {
      const { error } = await cb(to.params);
      if (error) {
        ElMessage.error(error.message);
        router.replace(path);
      }
    }
  });
};

export const useRedirectOnMounted = (
  cb: (param: any) => Promise<IResponse>,
  params: any,
  path: string
) => {
  const router = useRouter();

  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );

  onMounted(async () => {
    if (!isEmpty(params)) {
      const { error } = await cb(params);
      if (error) {
        ElMessage.error(error.message);
        router.replace(path);
      }
    }
  });
};
